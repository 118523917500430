@import "../utilities/scss/breakpoints";

.Toolbar {
  margin-bottom: 2rem;
  display: grid;
  grid-template-columns: auto 11rem 7rem;
  column-gap: 1rem;
  position: sticky;
  top: 0;
  background-color: var(--light-color);
  z-index: 1;

  & > button {
    grid-column: 2;

    &:only-of-type {
      grid-column: -1;
    }
  }
  & > button + button {
    grid-column: 3;
  }

  @include respondTo(sm) {
    grid-template-columns: auto auto;
    grid-template-rows: 1fr 1fr;
    row-gap: 1rem;

    & > *.toggleSwitch {
      grid-column: 1 / span 2;
      grid-row: 1;
    }
    & > button {
      grid-column: 1 / 1;
      grid-row: 2;
    }
    & > button + button {
      grid-column: 2/ 2;
      grid-row: 2;
    }
  }

  button {
    font-size: 1.25rem;
  }
}
