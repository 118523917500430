.toggleSwitch {
  min-width: 200px;
  display: inline-block;
  font-size: 1.25rem;
  text-transform: uppercase;

  input[type="checkbox"] {
    height: 0;
    width: 0;
    visibility: hidden;
    display: none;
  }

  label {
    cursor: pointer;
    text-indent: 55px;
    width: 45px;
    height: 21px;
    display: block;
    border-radius: 21.25px;
    position: relative;
    white-space: nowrap;
    border: 3px solid var(--accent-color);
    top: calc(50% - 16px);
  }

  label:after {
    content: "";
    position: absolute;
    top: 1.25px;
    left: 3px;
    width: 17.5px;
    height: 17.5px;
    background: #fff;
    box-shadow: 0 0 0 3px var(--accent-color) inset;
    border-radius: 22.5px;
    transition: 0.3s;
  }

  // input:checked + label,
  &.isActive input + label {
    background: var(--accent-color);
  }

  // input:checked + label:after,
  &.isActive input + label:after {
    left: calc(100% - 1.25px);
    transform: translateX(-100%);
    box-shadow: 0 0 0 0px white;
  }

  // label:active:after,
  &.isActive label:active:after {
    width: 32.5px;
  }
}
