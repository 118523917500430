@import "../utilities/scss/breakpoints";

.Menu {
  $block: &;
  position: absolute;
  left: 2rem;
  top: 2rem;
  display: flex;
  flex-direction: column;

  @include respondTo(sm) {
    visibility: hidden;
  }

  & > * {
    margin-bottom: 1rem;
  }
}
