@keyframes scaleDownEffect {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.7);
  }
}

@keyframes slideDown {
  0% {
    transform: translateY(-100px);
  }
  50% {
    opacity: 0.5;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
