@import "../utilities/scss/breakpoints";

.Modal {
  $block: &;
  visibility: hidden;
  max-width: 600px;
  position: fixed;
  top: 1rem;
  left: calc(50% - 300px);
  background-color: var(--main-color);
  color: white;
  margin: 1rem;
  border-radius: 1rem;
  z-index: 2;
  padding: 2rem;
  line-height: 1.5;
  box-shadow: 0 0px 40px 0 var(--shadow-color);
  transition: all 0.5s ease-in-out;
  opacity: 0;
  font-size: 1.25rem;
  font-family: "Barlow", sans-serif;
  overflow-y: scroll;

  @include respondTo(sm) {
    left: 0;
    max-width: 100%;
    font-size: 1rem;
  }

  &__title {
    font-size: 2rem;
    margin-top: 0;
    margin-bottom: 0rem;
    font-family: "Barlow Condensed", sans-serif;
  }

  p {
    margin-bottom: 0.75rem;
    margin-top: 0.5rem;
  }
  a {
    color: var(--accent-secondary-color);
  }

  &__backDrop {
    visibility: hidden;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: url("/close-icon.svg"), auto;
    opacity: 1;
    z-index: 1;
    background: var(--main-color-light);
  }

  &.is-active {
    visibility: visible;
    animation: slideDown 0.5s forwards ease-in-out;

    & + #{$block}__backDrop {
      visibility: visible;
    }
  }
  &__closeButton {
    cursor: pointer;
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 2rem;
  }
}
