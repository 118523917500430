@import "../utilities/scss/breakpoints";

.Footer {
  padding: 0.4rem 0 1rem;
  margin-top: auto;
  border-top: 1px solid var(--main-color-light);
  color: var(--light-text);
  text-transform: uppercase;
  letter-spacing: 0.07rem;

  width: 260px;

  @include respondTo(sm) {
    width: 100%;
    text-align: center;
    padding-top: 1rem;
  }

  a {
    color: var(--light-text);
  }
}
